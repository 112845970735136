<template>
  <div class="pt-10">
    <div class="vx-col  w-auto">
      <vx-card>
        <h2>Create Admin User</h2>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" class="w-full lg:w-3/4">
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full md:w-1/2 md:pr-5">
            <div class="w-full mb-5">
              <vs-input
                :success="
                !errors.first('fullName') && userData.fullName != ''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('fullName') ? true : false"
                v-validate="'required|alpha_spaces|max:150'"
                name="fullName"
                data-vv-as="fullName"
                label="Full Name"
                icon-no-border
                icon="icon icon-user"
                icon-pack="feather"
                class="w-full"
                v-model="userData.fullName"
              />
              <span class="text-danger text-sm">{{ errors.first("fullName") }}</span>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full md:w-1/2 md:pr-5">
            <div class="w-full mb-5">
              <vs-input
                :success="!errors.first('email') && userData.email != ''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('email') ? true : false"
                v-validate="'required|email'"
                name="email"
                data-vv-as="email"
                label="Email"
                icon-no-border
                icon="icon icon-mail"
                icon-pack="feather"
                class="w-full"
                v-model="userData.email"
              />
              <span class="text-danger text-sm">{{ errors.first("email") }}</span>
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
            <div class="w-full mb-5">
              <label class="vs-input--label">Account Status</label>
              <vs-switch v-model="userData.status"/>
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
            <div class="w-full mb-5">
              <vs-button class="mr-3 mb-2" @click.prevent="submitForm">Submit</vs-button>
              <vs-button color="danger" class="mb-2"
                         @click="clear">Clear
              </vs-button>
            </div>
          </vs-col>
        </vs-row>
      </vx-card>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  data() {
    return {
      userData: {
        fullName: "",
        email: "",
        status: false,
      },
    }
  },
  methods: {
    ...mapActions("adminUser", ["storeAdminUser"]),
    clear() {
      this.userData.fullName = "";
      this.userData.email = "";
      this.userData.status = false;
      this.errors.clear();
      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
    submitForm() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading()
          this.storeAdminUser(this.userData)
            .then(res => {
              this.$vs.loading.close()
              this.$vs.notify({
                title: 'Success',
                text: "Admin Created Successfully",
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'success'
              })
              this.$router.push({name: 'list-admin'});
            })
            .catch(err => {
              this.$vs.loading.close()
              if (err.response.status === 422) {
                this.errors.add({
                  field: "email",
                  msg: err.response.data.message
                });
              } else {
                this.$vs.notify({
                  title: 'Failure',
                  text: "Create Admin Failed",
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'danger'
                })
              }
            })
        }
      })
    }
  },
}
</script>
